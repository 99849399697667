// QuotationDetail.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, InputAdornment } from '@mui/material';
import { apiCall } from '../api';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const EditPositionModal = ({ isEditModalOpen, setIsEditModalOpen, editingPosition, refetchQuotationPositions }) => {
    const getModalStyle = () => {
        const top = 50;
        const left = 50;
    
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    };
    const [modalStyle] = useState(getModalStyle);
    const [updatedPosition, setUpdatedPosition] = useState({});    

    useEffect(() => {
        // Update `updatedPosition` when `editingPosition` changes
        if (editingPosition && editingPosition.attributes) {
            setUpdatedPosition(editingPosition.attributes);
        }
    }, [editingPosition]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedPosition(prev => {
            const updated = { ...prev, [name]: value };
            updated.QPositionPrice = (updated.QPositionSinglePrice * updated.QPositionQuantity * (1 - (updated.QPositionDiscount || 0) / 100)).toFixed(2);
            return updated;
        });
    };  

    const handleUpdatePosition = async (e) => {
        e.preventDefault();
        try {
            // Ensure QPositionTaxrate is a valid value
            const validTaxRates = ["Voll", "Ermindert", "Kein"];
            if (!validTaxRates.includes(updatedPosition.QPositionTaxrate)) {
                setUpdatedPosition(prev => ({ ...prev, QPositionTaxrate: "Voll" }));
            }
    
            // API call to update the position
            await apiCall({
                endpoint: `quotation-positions/${editingPosition.id}`,
                method: 'PUT',
                data: {
                    data: updatedPosition
                }
            });
            // Close the modal
            setIsEditModalOpen(false);
            // Refetch the quotation positions
            refetchQuotationPositions();
        } catch (error) {
            console.error('Error updating position:', error);
        }
    };

    return (
        <Modal
            open={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
        >
            <Box style={modalStyle} className="edit-modal">
                <h2>Edit Position</h2>
                <form onSubmit={handleUpdatePosition}>
                    <label>
                        Title:
                        <input
                            type="text"
                            name="QPositionTitle"
                            value={updatedPosition.QPositionTitle || ""}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Description:
                        <input
                            type="text"
                            name="QPositionDescription"
                            value={updatedPosition.QPositionDescription || ""}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Quantity:
                        <input
                            type="number"
                            name="QPositionQuantity"
                            value={updatedPosition.QPositionQuantity || ""}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Single Price:
                        <input
                            type="number"
                            name="QPositionSinglePrice"
                            value={updatedPosition.QPositionSinglePrice || ""}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Discount:
                        <input
                            type="number"
                            name="QPositionDiscount"
                            value={updatedPosition.QPositionDiscount || ""}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Tax Rate:
                        <Select
                            name="QPositionTaxrate"
                            value={updatedPosition.QPositionTaxrate || "Voll"}
                            onChange={handleInputChange}
                        >
                            <MenuItem value="Voll">Voll</MenuItem>
                            <MenuItem value="Ermindert">Ermindert</MenuItem>
                            <MenuItem value="Kein">Kein</MenuItem>
                        </Select>
                    </label>
                    <label>
                        Price:
                        <input
                            type="text"
                            name="QPositionPrice"
                            value={(updatedPosition.QPositionSinglePrice * updatedPosition.QPositionQuantity * (1 - (updatedPosition.QPositionDiscount || 0) / 100)).toFixed(2)}
                            readOnly
                        />
                    </label>
                    <button type="submit">Update Position</button>
                </form>
            </Box>
        </Modal>
    );  
};  

const QuotationDetail = () => {
    const { quotationId } = useParams();
    const [quotationData, setQuotationData] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingPosition, setEditingPosition] = useState(null);

    const handleEdit = (position) => {
        setEditingPosition(position);
        setIsEditModalOpen(true);
    };    
          
    useEffect(() => {
        const fetchQuotationData = async () => {
          try {
            const data = await apiCall({ endpoint: `quotations/${quotationId}?populate=*` });
            setQuotationData(data.data.attributes);
          } catch (error) {
            console.error('Error fetching quotation data:', error);
            // Handle error (e.g., set an error state)
          }
        };
        fetchQuotationData();
      }, [quotationId]);

    const [quotationPositions, setQuotationPositions] = useState([]);

    const fetchQuotationPositions = async () => {
        try {
            const data = await apiCall({ endpoint: `quotation-positions?filters[quotation][id][$eq]=${quotationId}&populate=*` });
            console.log('Quotation Positions Data:', data);  // Log the raw API response
            setQuotationPositions(data.data);
        } catch (error) {
            console.error('Error fetching quotation positions:', error);
        }
    };

    useEffect(() => {
        fetchQuotationPositions();
    }, [quotationId]);

    useEffect(() => {
        console.log('Quotation Positions State:', quotationPositions);  // Log the state
    }, [quotationPositions]);

    // Define state for new position form inputs
    const [newPosition, setNewPosition] = useState({
        QPositionTitle: "",
        QPositionDescription: "",
        QPositionQuantity: 0,
        QPositionSinglePrice: 0,
        QPositionDiscount: 0,
        QPositionTaxrate: "Voll",
        // Other attributes...
    });

    const handleDuplicate = async (position) => {
        try {
            const data = await apiCall({
                endpoint: 'quotation-positions',
                method: 'POST',
                data: {
                    data: {
                        ...position.attributes,
                        quotation: quotationId
                    }
                }
            });
            setQuotationPositions(prev => [...prev, data.data]);
        } catch (error) {
            console.error('Error duplicating position:', error);
        }
    };   
    
    const handleRemove = async (positionId) => {
        try {
            await apiCall({
                endpoint: `quotation-positions/${positionId}`,
                method: 'DELETE',
            });
            setQuotationPositions(prev => prev.filter(pos => pos.id !== positionId));
        } catch (error) {
            console.error('Error removing position:', error);
        }
    };    

    // Define a handler for form submission
    const handleAddPosition = async (e) => {
        e.preventDefault();
        try {
            // Ensure QPositionTaxrate is a valid value
            const validTaxRates = ["Voll", "Ermindert", "Kein"];
            if (!validTaxRates.includes(newPosition.QPositionTaxrate)) {
                setNewPosition(prev => ({ ...prev, QPositionTaxrate: "Voll" }));
            }
    
            // API call to add the position
            const data = await apiCall({
                endpoint: 'quotation-positions',
                method: 'POST',
                data: {
                    data: {
                        ...newPosition,
                        QPositionPrice: (newPosition.QPositionSinglePrice * newPosition.QPositionQuantity * (1 - (newPosition.QPositionDiscount || 0) / 100)).toFixed(2),
                        quotation: quotationId
                    }
                }
            });
            setQuotationPositions(prev => [...prev, data.data]);
        } catch (error) {
            console.error('Error adding new position:', error);
        }
    };        

    const handleNewPositionChange = (e) => {
        const { name, value } = e.target;
        setNewPosition(prev => {
            const updated = { ...prev, [name]: value };
            updated.QPositionPrice = (updated.QPositionSinglePrice * updated.QPositionQuantity * (1 - (updated.QPositionDiscount || 0) / 100)).toFixed(2);
            return updated;
        });
    };   

    const totalPrice = quotationPositions.reduce((total, position) => {
        return total + (position.attributes.QPositionSinglePrice * position.attributes.QPositionQuantity * (1 - (position.attributes.QPositionDiscount || 0) / 100));
    }, 0);

    if (!quotationData) {
        return <Box><LinearProgress />        </Box>;
    }

    return (
        <Box className="quotation-detail-container" p={3}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h1>{quotationData.QuotationName}</h1>
                </Grid>
                <Grid item xs={12}>
                    <Box className='QuotationFields'>
                        <TextField fullWidth id="quotation-number" label="Angebotsname" variant="standard" value={quotationData.quotationNumber || ""} readOnly />  
                        <TextField fullWidth id="quotation-name" label="Angebotsname" variant="standard" value={quotationData.QuotationName || ""} readOnly />
                        <TextField 
                            fullWidth 
                            id="quotation-client" 
                            label="Kunde" 
                            variant="standard" 
                            value={
                                (quotationData.client && 
                                quotationData.client.data && 
                                quotationData.client.data.attributes && 
                                quotationData.client.data.attributes.CompanyName) 
                                ? quotationData.client.data.attributes.CompanyName 
                                : "Kein Kunde zugeordnet"
                            } 
                            readOnly 
                        />
                        <TextField 
                            fullWidth 
                            id="quotation-project" 
                            label="Projekt" 
                            variant="standard" 
                            value={
                                (quotationData.project && 
                                quotationData.project.data && 
                                quotationData.project.data.attributes && 
                                quotationData.project.data.attributes.ProjectName) 
                                ? quotationData.project.data.attributes.ProjectName 
                                : "Kein Projekt zugeordnet"
                            } 
                            readOnly 
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Single Price</TableCell>
                                    <TableCell>Discount</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {quotationPositions.map((position) => (
                                <TableRow key={position.id}>
                                    <TableCell>{position.attributes.QPositionTitle}</TableCell>
                                    <TableCell>{position.attributes.QPositionDescription}</TableCell>
                                    <TableCell>{position.attributes.QPositionQuantity}</TableCell>
                                    <TableCell>{position.attributes.QPositionSinglePrice} €</TableCell>
                                    <TableCell>{position.attributes.QPositionDiscount}</TableCell>
                                    <TableCell>{(position.attributes.QPositionSinglePrice * position.attributes.QPositionQuantity * (1 - (position.attributes.QPositionDiscount || 0) / 100)).toFixed(2)} €</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEdit(position)}><EditIcon /></Button>
                                        <Button onClick={() => handleDuplicate(position)}><ContentCopyIcon /></Button>
                                        <Button onClick={() => handleRemove(position.id)}><DeleteIcon /></Button>
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={5} align="right"><strong>Total Price:</strong></TableCell>
                                    <TableCell align="right"><strong>{totalPrice.toFixed(2)} €</strong></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid className='addPositionForm' mt={3} item xs={8}>
                        <form onSubmit={handleAddPosition}>
                        <h2>Neue Position</h2>
                            <Grid container gap={2}>
                                <TextField
                                    label="Title"
                                    name="QPositionTitle"
                                    value={newPosition.QPositionTitle}
                                    onChange={(e) => setNewPosition({ ...newPosition, QPositionTitle: e.target.value })}
                                />
                                <TextField
                                    label="Description"
                                    name="QPositionDescription"
                                    value={newPosition.QPositionDescription}
                                    onChange={(e) => setNewPosition({ ...newPosition, QPositionDescription: e.target.value })}
                                />
                               <TextField
                                    label="Single Price"
                                    name="QPositionSinglePrice"
                                    type="number"
                                    value={newPosition.QPositionSinglePrice}
                                    onChange={handleNewPositionChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                    }}
                                />
                                <TextField
                                    label="Quantity"
                                    name="QPositionQuantity"
                                    type="number"
                                    value={newPosition.QPositionQuantity}
                                    onChange={handleNewPositionChange}
                                />
                                <TextField
                                    label="Discount"
                                    name="QPositionDiscount"
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    value={newPosition.QPositionDiscount}
                                    onChange={handleNewPositionChange}
                                />
                                <TextField
                                    label="Tax Rate"
                                    name="QPositionTaxrate"
                                    select
                                    value={newPosition.QPositionTaxrate || "Voll"}
                                    onChange={handleNewPositionChange}
                                >
                                    <MenuItem value="Voll">Voll</MenuItem>
                                    <MenuItem value="Ermindert">Ermindert</MenuItem>
                                    <MenuItem value="Kein">Kein</MenuItem>
                                </TextField>
                                <TextField
                                    label="Price"
                                    name="QPositionPrice"
                                    value={(newPosition.QPositionSinglePrice * newPosition.QPositionQuantity * (1 - (newPosition.QPositionDiscount || 0) / 100)).toFixed(2)}
                                    readOnly
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                    }}
                                />
                                <Button variant="contained" color="primary" type="submit">Add Position</Button>
                            </Grid>
                        </form>
                    </Grid>
                    <EditPositionModal 
                        isEditModalOpen={isEditModalOpen} 
                        setIsEditModalOpen={setIsEditModalOpen} 
                        editingPosition={editingPosition}
                        refetchQuotationPositions={fetchQuotationPositions}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default QuotationDetail;