import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

function SearchBar({ onSearch, clearTimestamp, hasResults }) {
    const [query, setQuery] = useState("");
    const debouncedSearch = debounce(function(query) {
      onSearch(query);
    }, 300);

    useEffect(() => {
      if (query.length > 1) {
        debouncedSearch(query);
      } else if (query.length === 0) {
        onSearch(""); 
      }
  
      // Clean-up function
      return () => {
        debouncedSearch.cancel();
      };
    }, [query]);

    useEffect(() => {
      if (clearTimestamp) {
        setQuery("");
      }
    }, [clearTimestamp]);

    return (
      <div>
        <Autocomplete
          freeSolo
          options={[]} 
          inputValue={query}
          onInputChange={(event, newInputValue) => {
            setQuery(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search..."
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                style: {
                  borderRadius: hasResults ? "40px 40px 0 0" : "40px",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>
    );
}

export default SearchBar;
