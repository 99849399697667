// App.js

import './App.css';
import { Button, CssBaseline, Drawer, Hidden, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import logo from './assets/kielteam_trnsprnt.png'
import logoDark from './assets/kielteam_trnsprnt_neg.png'
import { ThemeProvider } from '@mui/material/styles';
import getTheme from './components/theme';

import SearchComponent from './components/searchcomp/handleSearch';
import RouteChangeAlert from './components/widgets/RouteChangeAlert';
import LoggingWidget from './components/widgets/LoggingWidget';

import { Box, Grid } from '@mui/material';
import PermanentActions from './components/widgets/PermanentActions';
import { Switch, FormControlLabel } from '@mui/material';
import RouteViews from './components/RouteViews';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Login from './components/login';

function App() {
  const [token, setToken] = useState(localStorage.getItem('jwt'));

  const handleLogin = (jwt, username, id) => {
    localStorage.setItem('jwt', jwt);
    localStorage.setItem('username', username);
    localStorage.setItem('userId', id);
    setToken(jwt);
  };

  const handleLogout = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('username');
    localStorage.removeItem('userId');
    setToken(null);
    window.location.reload();
  };

  const username = localStorage.getItem('username');

  const prefersDarkMode = () => {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  };

  const [mode, setMode] = useState(prefersDarkMode() ? 'dark' : 'light');
  const toggleMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const [drawerOpen, setDrawerOpen] = useState(false); // State for Drawer

  const closeDrawerOnRouteChange = () => {
    console.log('closeDrawerOnRouteChange called');
    setDrawerOpen(false);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      setMode(mediaQuery.matches ? 'dark' : 'light');
    };
  
    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  const [version, setVersion] = useState("");
  
  useEffect(() => {
    // Fetch the version from version.txt
    fetch("/version.txt")
      .then((response) => response.text())
      .then((data) => {
        setVersion(data.trim()); // Set the version in state
      });
  }, []);

  const SideBarContent = () => (
    <Grid className='sideBar' item p={3} xs={12} lg={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} order={{ xs: 2, lg: 1 }}>
            <PermanentActions />
        </Grid>
        <Grid item xs={12} order={{ xs: 1, lg: 2 }}>
          <LoggingWidget />
        </Grid>
        <Grid item xs={12} order={{ xs: 3}}> 
          <FormControlLabel
              control={
                  <Switch
                      checked={mode === 'dark'}
                      onChange={toggleMode}
                      name="checkedDarkMode"
                      color="primary"
                  />
              }
              label="Dark Mode"
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={getTheme(mode)}>
      <CssBaseline />
      <Box className="App">
      {token ? (
          // The routes that should be accessible when logged in
          <>
           <Router>
            <Grid container alignItems={'center'}>
              <Hidden lgUp>
                <Grid item xs={3}>
                  <Button onClick={() => {
                      console.log('Open button clicked');
                      setDrawerOpen(true);
                    }}>
                    <MenuIcon fontSize="large" />
                  </Button>
                </Grid>
              </Hidden>
              <Grid item xs={9} lg={3} p={3}>
                <Link to="/">
                  <img src={mode === 'dark' ? logoDark : logo} className="App-logo" alt="logo" />
                </Link>
                <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}> {/* Control Drawer's open state */}
                  <Button onClick={(e) => { e.stopPropagation(); setDrawerOpen(false); }}><CloseIcon fontSize="large" /></Button> {/* Close Drawer */}
                  <Grid item xs={12} lg={2} p={3}>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                      <Typography>Hallo {username}.</Typography>
                      <Button variant="contained" color="primary" onClick={handleLogout}>
                        Logout
                      </Button>                      
                    </Grid>
                  </Grid>
                  <SideBarContent />
                </Drawer>
              </Grid>
              <Grid item xs={12} lg={7} p={3}>
                <SearchComponent />
              </Grid>
              <Hidden lgDown>
                <Grid item xs={12} lg={2} p={3}>
                  <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Typography>{version} user: {username}</Typography>
                    <Button variant="contained" color="primary" onClick={handleLogout}>
                      Logout
                    </Button>
                  </Grid>
                </Grid>
              </Hidden> 
            </Grid>
            <Grid container alignItems="flex-start">
              <Hidden lgDown>
                <SideBarContent />
              </Hidden>
              <Grid item xs={12} lg={9} p={3}>
                <RouteViews token={token} />
                
              </Grid>
            </Grid>
            <Grid item xs={12} order={{ xs: 3}}>             
              <RouteChangeAlert onCloseDrawer={closeDrawerOnRouteChange} />
            </Grid>
          </Router>
          </>
        ) : (
          // The login route
          <Login onLogin={handleLogin} />
        )}    
        
      </Box>
    </ThemeProvider>
  );
}

export default App;