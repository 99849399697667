// handleSearch.js

import React, { useState, useCallback } from 'react';
import SearchBar from './SearchBar.js';
import { Link } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { apiCall } from '../api'; // <-- Import the apiCall function
import ArticleIcon from '@mui/icons-material/Article';

function SearchComponent() {
  const [results, setResults] = useState([]);
  const [clearTimestamp, setClearTimestamp] = useState(null);
  const theme = useTheme();

  const search = async (query) => {
      if (query.length === 0 || query.length < 2) {
          setResults([]);
          return;
      }
      
      try {
          const [clientsResponse, projectsResponse, quotationsResponse] = await Promise.all([
              apiCall({ endpoint: `clients?_q=${query}` }),
              apiCall({ endpoint: `projects?_q=${query}&populate=client` }),
              apiCall({ endpoint: `quotations?_q=${query}&populate=client` })
          ]);

          const clients = clientsResponse.data.map(client => ({
              type: 'client',
              id: client.id,
              name: client.attributes.CompanyName,
              // ... other attributes you want to include
          }));
          
          const projects = projectsResponse.data.map(project => ({
              type: 'project',
              id: project.id,
              name: project.attributes.ProjectName,
              projectnumber: project.attributes.projectNumber,
          }));

          const quotations = quotationsResponse.data.map(quotation => ({
            type: 'quotation',
            id: quotation.id,
            name: quotation.attributes.QuotationName,
            quotationnumber: quotation.attributes.quotationNumber,
            }));

          // Combine clients and projects
          const combinedResults = [...clients, ...projects, ...quotations];

          // Set the results state
          setResults(combinedResults);
      } catch (error) {
          console.error("Error fetching search results:", error);
          setResults([]);
      }
  };   

  const handleResultClick = useCallback(() => {
      setResults([]);
      setClearTimestamp(Date.now());  // Set the current time to indicate the search bar should be cleared
  }, []);

  return (
    <div className="search-bar-container">
        <Grid>
            <SearchBar onSearch={search} clearTimestamp={clearTimestamp} hasResults={results.length > 0} />
            {results.length > 0 && (
                <List className='searchResultList' style={{
                    backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#ffffff'
                }}>
                    <Grid>
                        {results.map(result => (
                            <ListItem key={`${result.type}-${result.id}`} button component={Link} to={`/${result.type}s/${result.id}`} onClick={handleResultClick}>
                                {result.type === 'client' 
                                    ? <PersonIcon style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} /> 
                                    : result.type === 'project'
                                    ? <WorkIcon style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
                                    : result.type === 'quotation' 
                                    ? <ArticleIcon style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
                                    : ''
                                }
                                <ListItemText style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}
                                    primary={`${result.type}: ${result.name} - ${result.id}`}
                                />{
                                    result.type === 'project' 
                                      ? <span>{result.projectnumber}</span> 
                                      : result.type === 'quotation' 
                                        ? <span>{result.quotationnumber}</span> 
                                        : ''
                                  }
                            </ListItem>
                        ))}
                    </Grid>
                </List>
            )}
        </Grid>
    </div>
);
}

export default SearchComponent;