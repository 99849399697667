// ClientDetail.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import html2pdf from 'html2pdf.js';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { apiCall } from '../api'; // <-- Import the apiCall function

const ClientDetail = () => {
    const { clientId } = useParams();
    const [clientData, setClientData] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [updatedClientData, setUpdatedClientData] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();
    const navigateToProjects = () => {
        navigate(`/projects?clientId=${clientId}`);
    };  
    const navigateToQuotations = () => {
        navigate(`/quotations?clientId=${clientId}`);
    };       

    const handleFieldChange = (e, field) => {
        setUpdatedClientData(prevState => ({
            ...prevState,
            [field]: e.target.value,
        }));
    };

    const handleConfirmSave = () => {
        saveChanges();
        setOpenDialog(false);
    };

    const handleOpenDialog = () => {
        if (Object.keys(updatedClientData).length === 0) {
            console.warn("No changes made to the client data.");
            return;
        }
        setOpenDialog(true);
    };

    const saveChanges = async () => {
        console.log("saveChanges function called");
        if (Object.keys(updatedClientData).length === 0) {
            console.warn("No changes made to the client data.");
            return;
        }
    
        const payload = {
            data: updatedClientData
        };
        console.log("Updated client data:", updatedClientData);
        try {
            const response = await apiCall({ 
                endpoint: `clients/${clientId}?populate=*`, 
                method: 'PUT', 
                data: payload 
            });
            setClientData(response.data.attributes);
            setIsEditable(false);
            console.log("Client data updated!");
        } catch (error) {
            console.error('Error updating client data:', error);
        }
    };
    
    const toggleEdit = () => {
        if (isEditable) {
            setUpdatedClientData({});
        } else {
            setUpdatedClientData(clientData);
        }
        setIsEditable(!isEditable);
    };
    
    const generatePDF = () => {
        const element = document.querySelector('.client-detail-container'); // This selects the container you want to turn into a PDF
        const currentDate = new Date().toISOString().slice(0,10); // YYYY-MM-DD format
        const filename = `${currentDate}_${clientData.CompanyName}_Kunde.pdf`;
    
        const opt = {
            margin:       10,
            filename:     filename,
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 2 },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
    
        html2pdf().from(element).set(opt).save();
    }        

    useEffect(() => {
        const fetchClientData = async () => {
            try {
                const response = await apiCall({ endpoint: `clients/${clientId}?populate=*` });
                setClientData(response.data.attributes);
            } catch (error) {
                console.error('Error fetching client data:', error);
            }
        };

        fetchClientData();
    }, [clientId]);

    if (!clientData) {
        return <div>Loading...</div>;
    }

    return (
        <Box className="client-detail-container" sx={{ flexGrow: 1 }} p={3}>
            <Grid container spacing={3}>
                <Grid item xs={12}>                   
                    <Grid container className='CompanyFields' alignItems="center" flexDirection={'column'}>
                        <Grid item xs={12}>
                            <Button onClick={toggleEdit}>
                                {isEditable ? <><CancelIcon /> Cancel</> : <><EditIcon /> Edit</>}
                            </Button>
                            {isEditable && 
                                <Button onClick={handleOpenDialog}>
                                    <SaveIcon /> Save
                                </Button>
                            }
                            <h1>{clientData.CompanyName}</h1>
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            <Grid item xs={8} alignItems="center">
                                <TextField
                                    id="company-email"
                                    label="Email"
                                    variant="standard"
                                    value={isEditable ? updatedClientData.CompanyEmail || "" : clientData.CompanyEmail || ""}
                                    readOnly={!isEditable}
                                    onChange={e => handleFieldChange(e, 'CompanyEmail')}
                                    style={{ backgroundColor: isEditable ? 'white' : 'transparent' }}
                                />
                                <TextField
                                    id="additional-name"
                                    label="Additional Name"
                                    variant="standard"
                                    value={isEditable ? updatedClientData.CompanyNameAdd || "" : clientData.CompanyNameAdd || ""}
                                    readOnly={!isEditable}
                                    onChange={e => handleFieldChange(e, 'CompanyNameAdd')}
                                    style={{ backgroundColor: isEditable ? 'white' : 'transparent' }}
                                />
                                <TextField
                                    id="company-street"
                                    label="Street"
                                    variant="standard"
                                    value={isEditable ? updatedClientData.CompanyStreet || "" : clientData.CompanyStreet || ""}
                                    readOnly={!isEditable}
                                    onChange={e => handleFieldChange(e, 'CompanyStreet')}
                                    style={{ backgroundColor: isEditable ? 'white' : 'transparent' }}
                                />
                                <TextField
                                    id="zip-code"
                                    label="ZIP Code"
                                    variant="standard"
                                    value={isEditable ? updatedClientData.CompanyZIP || "" : clientData.CompanyZIP || ""}
                                    readOnly={!isEditable}
                                    onChange={e => handleFieldChange(e, 'CompanyZIP')}
                                    style={{ backgroundColor: isEditable ? 'white' : 'transparent' }}
                                />
                                <TextField
                                    id="company-city"
                                    label="City"
                                    variant="standard"
                                    value={isEditable ? updatedClientData.CompanyCity || "" : clientData.CompanyCity || ""}
                                    readOnly={!isEditable}
                                    onChange={e => handleFieldChange(e, 'CompanyCity')}
                                    style={{ backgroundColor: isEditable ? 'white' : 'transparent' }}
                                />
                                <TextField
                                    id="company-country"
                                    label="Country"
                                    variant="standard"
                                    value={isEditable ? updatedClientData.CompanyCountry || "" : clientData.CompanyCountry || ""}
                                    readOnly={!isEditable}
                                    onChange={e => handleFieldChange(e, 'CompanyCountry')}
                                    style={{ backgroundColor: isEditable ? 'white' : 'transparent' }}
                                />
                                <TextField
                                    id="company-phone"
                                    label="Phone"
                                    variant="standard"
                                    value={isEditable ? updatedClientData.CompanyPhone || "" : clientData.CompanyPhone || ""}
                                    readOnly={!isEditable}
                                    onChange={e => handleFieldChange(e, 'CompanyPhone')}
                                    style={{ backgroundColor: isEditable ? 'white' : 'transparent' }}
                                />
                                <TextField
                                    id="company-url"
                                    label="URL"
                                    variant="standard"
                                    value={isEditable ? updatedClientData.CompanyURL || "" : clientData.CompanyURL || ""}
                                    readOnly={!isEditable}
                                    onChange={e => handleFieldChange(e, 'CompanyURL')}
                                    style={{ backgroundColor: isEditable ? 'white' : 'transparent' }}
                                />
                                <TextField id="created-at" label="Created At" variant="standard" value={new Date(clientData.createdAt).toLocaleString()} readOnly />
                                <TextField id="updated-at" label="Updated At" variant="standard" value={new Date(clientData.updatedAt).toLocaleString()} readOnly />
                                <TextField id="published-at" label="Published At" variant="standard" value={new Date(clientData.publishedAt).toLocaleString()} readOnly />
                                <TextField id="locale" label="Locale" variant="standard" value={clientData.locale || ""} readOnly />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <h2>Actions</h2>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Button startIcon={<PictureAsPdfIcon />} variant="contained" color="primary" onClick={generatePDF}>Create PDF</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={navigateToProjects}>Projekte</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={navigateToQuotations}>Angebote</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>            
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Changes"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to save these changes?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmSave} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ClientDetail;
