// NewProject.js

import React from 'react';
import Box from '@mui/material/Box';
import CreateProject from '../widgets/CreateProject';
import { Grid } from '@mui/material';

const NewProject = () => {
  return (
    <Box className="new-container" sx={{ flexGrow: 1 }}>
        <h1>Neues <strong>Projekt</strong></h1>
        <Grid container justifyContent="center" p={3}>
            <Grid item xs={6}> {/* This will take up half the space of its container */}
                <CreateProject />
            </Grid>
        </Grid>
    </Box>
  );
};


export default NewProject;