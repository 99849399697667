// RouteViews.js
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Routes, Route } from 'react-router-dom';
import HomePage from './routes/HomePage';
import { Grid } from '@mui/material';
import ClientList from './routes/ClientList';
import ClientDetail from './routes/ClientDetail';
import NewClient from './routes/NewClient';
import ProjectList from './routes/ProjectList';
import ProjectDetail from './routes/ProjectDetail';
import NewProject from './routes/NewProject';
import QuotationList from './routes/QuotationList';
import QuotationDetail from './routes/QuotationDetail';
import NewQuotation from './routes/NewQuotation';
import InvoiceList from './routes/InvoiceList';
import NewInvoice from './routes/NewInvoice';

function RouteViews({ token }) {  
  const theme = useTheme();

  return (
    <Grid 
      className='routeViews' 
      container 
      style={{
        maxHeight: 'calc(100vh - 200px)', 
        overflowY: 'auto', 
        backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : 'white'
      }}
    >
      <Routes>
        {token ? (
          // The routes that should be accessible when logged in
          <>
            <Route path="/" element={<HomePage />} exact />
            <Route path="/clients" exact element={<ClientList/>} />
            <Route path="/clients/:clientId" element={<ClientDetail />} />
            <Route path="/clients/new" element={<NewClient />} />
            <Route path="/projects" exact element={<ProjectList />} />
            <Route path="/projects/:projectId" element={<ProjectDetail />} />
            <Route path="/projects/new" element={<NewProject />} />
            <Route path="/quotations" exact element={<QuotationList />} />
            <Route path="/quotations/:quotationId" element={<QuotationDetail />} />
            <Route path="/quotations/new" element={<NewQuotation />} />
            <Route path="/invoices" exact element={<InvoiceList />} />
            <Route path="/invoices/new" exact element={<NewInvoice />} />
          </>
        ) : (
          ''
        )}
      </Routes>
    </Grid>
  );
}

export default RouteViews;
