// theme.js

import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#EAEAEA',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 32,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          paddingLeft: '16px',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 32,
          backgroundColor: '#fff',
        },
        notchedOutline: {
          "& legend": {
            marginLeft: '12px',
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 32,
        },
      },
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 32,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          paddingLeft: '16px',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 32,
          backgroundColor: '#1e1e1e',
        },
        notchedOutline: {
          "& legend": {
            marginLeft: '12px',
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 32,
        },
      },
    },
  },
});

const getTheme = (mode) => (mode === 'dark' ? darkTheme : lightTheme);

export default getTheme;
