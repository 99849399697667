// login.js
import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from './config';
import { Button, TextField, Grid, Container, CssBaseline } from '@mui/material';
import logo from '../assets/kielteam_trnsprnt.png'
import logoDark from '../assets/kielteam_trnsprnt_neg.png'
import { useTheme } from '@mui/material/styles';

const Login = ({ onLogin }) => {
  const [credentials, setCredentials] = useState({ identifier: '', password: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const theme = useTheme();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${API_URL}/auth/local`, credentials)
      .then(response => {
        const { jwt, user } = response.data;
        onLogin(jwt, user.username, user.id);
      })
      .catch(error => {
        console.error('Error logging in:', error);
        if (error.response && error.response.data && error.response.data.error) {
          alert(error.response.data.error.message);
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <div>
            <Grid item><img src={theme.palette.mode === 'dark' ? logoDark : logo} className="App-logo" alt="logo" />
            </Grid> 
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent={'flex-end'} mt={5}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="identifier"
                label="Username or Email"
                name="identifier"
                autoComplete="email"
                autoFocus
                value={credentials.identifier}
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={credentials.password}
                onChange={handleChange}
              />
              <Grid item xs={3} mt={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Container>
  );
};

export default Login;
