// api.js
import axios from 'axios';
import { API_URL } from './config';

export const apiCall = async ({ endpoint, method = 'GET', data = null }) => {
  const token = localStorage.getItem('jwt');
  try {
    const response = await axios({
      method,
      url: `${API_URL}/${endpoint}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      handleUnauthorized();
    }
    throw error;
  }
};

const handleUnauthorized = () => {
  localStorage.removeItem('jwt');
  localStorage.removeItem('username');
  localStorage.removeItem('userId');
  window.location.reload();
};
