// PermanentActions.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useTheme } from '@mui/material/styles';

const PermanentActions = () => {
    const theme = useTheme();
    return (
        <Grid className='permanentActions' container flexDirection="column" p={3} alignItems="flex-start"
        style={{
            backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : 'white'
        }}>
            <Link className='permanentActionButton' to="/projects/new" 
                style={{ textDecoration: 'none', color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>
                <span>Neues <strong>Projekt</strong></span>
                <OpenInNewIcon style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
            </Link>
            <Link className='permanentActionButton' to="/quotations/new" style={{ textDecoration: 'none', color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>
                <span>Neues <strong>Angebot</strong></span><OpenInNewIcon style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
            </Link>
            <Link className='permanentActionButton' to="/invoices/new" style={{ textDecoration: 'none', color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>
                <span>Neue <strong>Rechnung</strong></span><OpenInNewIcon style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
            </Link>
            <Link className='permanentActionButton' to="/clients/new" style={{ textDecoration: 'none', color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>
                <span>Neuer <strong>Kunde</strong></span><OpenInNewIcon style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
            </Link>
            <Link className='permanentActionButton' to="/clients" style={{ textDecoration: 'none', color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>
                <span><strong>Kunden</strong></span><FormatListBulletedIcon style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
            </Link>
            <Link className='permanentActionButton' to="/projects" style={{ textDecoration: 'none', color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>
                <span><strong>Projekte</strong></span><FormatListBulletedIcon style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
            </Link>
            <Link className='permanentActionButton' to="/quotations" style={{ textDecoration: 'none', color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>
                <span><strong>Angebote</strong></span><FormatListBulletedIcon style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
            </Link>
            <Link className='permanentActionButton' to="/invoices" style={{ textDecoration: 'none', color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>
                <span><strong>Rechnungen</strong></span><FormatListBulletedIcon style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
            </Link>
        </Grid>
    );
}

export default PermanentActions;
