// LoggingWidget.js

import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { apiCall } from '../api'; // <-- Import the apiCall function

const LoggingWidget = ({ defaultProject }) => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [logDuration, setLogDuration] = useState(0);
    const [logDescription, setLogDescription] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [lastLoggedProjectId, setLastLoggedProjectId] = useState(null);
    const location = useLocation();
    const theme = useTheme();
    const [logDurationInput, setLogDurationInput] = useState('');

    useEffect(() => {
        if (defaultProject) {
            setSelectedProject(defaultProject);
        }
    }, [defaultProject]);

    useEffect(() => {
        const fetchServicesAndProjects = async () => {
            try {
                const servicesData = await apiCall({ endpoint: 'services' });
                setServices(servicesData.data);
                
                let projectsData;
                if (!searchTerm || searchTerm.length < 2) {
                    projectsData = await apiCall({ endpoint: 'projects?populate=*' });
                } else {
                    projectsData = await apiCall({ endpoint: `projects?filters[$or][0][ProjectName][$contains]=${searchTerm}&filters[$or][1][client][CompanyName][$contains]=${searchTerm}&populate=*` });
                }
                setProjects(projectsData.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchServicesAndProjects();
    }, [searchTerm]);

    const isNotLeistung = selectedService && selectedService.attributes.Typ !== "Leistung";

    const handleLogTime = async () => {
        if (!selectedProject || !selectedService) {
            setDialogMessage('Bitte ein Projekt und eine Leistung wählen.');
            setOpenDialog(true);
            return;
        }
      
        const payload = {
            data: {
                LogDuration: logDuration,
                LogDescription: logDescription,
                project: selectedProject.id,
                service: selectedService.id, 
                user: localStorage.getItem('userId'),
            }
        };

        console.log('Payload before POST:', payload);
    
        try {
            await apiCall({ endpoint: 'time-loggings', method: 'POST', data: payload });
            setLogDuration(0);
            setLogDurationInput('');
            setLogDescription('');
            setLastLoggedProjectId(selectedProject.id);
            setSelectedProject(null); 
            setSelectedService(null);
            setDialogMessage('Logged Time Successfully');
            setOpenDialog(true);
        } catch (error) {
            console.error('Error logging time:', error);
        }
    };
    
    const handleDurationChange = (e) => {
        let value = e.target.value;
        setLogDurationInput(value); // Set the raw input string
        value = value.replace(',', '.'); // Replace comma with period
        setLogDuration(Number(value));
    };  

  return (
    <Grid className='logWidget' item xs={12} p={3} 
        style={{
        backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#D9D9D9'
    }}>
        {!defaultProject && (
                <Autocomplete
                    options={projects}
                    getOptionLabel={(option) => `${option.attributes.projectNumber}  ${option.attributes.ProjectName} (${option.attributes.client.data.attributes.CompanyName})`}
                    onInputChange={(event, newInputValue) => {
                        setSearchTerm(newInputValue);
                    }}
                    onChange={(event, newValue) => setSelectedProject(newValue)}
                    renderInput={(params) => <TextField {...params} label="Projekt" variant="outlined" />}
                    value={selectedProject} 
                />
          )}
        <Autocomplete
            options={services}
            getOptionLabel={(option) => option.attributes.ServiceName}
            onChange={(event, newValue) => setSelectedService(newValue)}
            renderInput={(params) => <TextField {...params} label="Tätigkeit / Material" variant="outlined" />}
            value={selectedService}
        />
        <TextField
            fullWidth
            id="log-description"
            label="Beschreibung"
            variant="outlined"
            value={logDescription}
            onChange={(e) => setLogDescription(e.target.value)}
        />
        <Grid container className='sliderContainer'>
        <Slider
            value={logDuration}
            onChange={(event, newValue) => {
                setLogDuration(newValue);
                setLogDurationInput(String(newValue)); // Update the raw input string when the slider changes
            }}
            step={0.25}
            marks
            min={0}
            max={12}
            valueLabelDisplay="auto"
            disabled={isNotLeistung}
        />
        </Grid>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    id="log-duration"
                    label={isNotLeistung ? "Betrag (Euro)" : "Dauer (Stunden)"}
                    variant="outlined"
                    value={logDurationInput} // Use the raw input string here
                    onChange={handleDurationChange}
                />
            </Grid>
            <Grid item xs={4}>
                <Button variant="contained" color="primary" onClick={handleLogTime}>
                    Log
                </Button>
            </Grid>
        </Grid>
        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Benachrichtigung</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {dialogMessage}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => {
                setOpenDialog(false);
                if (lastLoggedProjectId) {  // Check if lastLoggedProjectId is not null
                    if (location.pathname === `/projects/${lastLoggedProjectId}`) {
                        window.location.reload();  // Force a refresh if already on the project page
                    } else {
                        navigate(`/projects/${lastLoggedProjectId}`);
                    }
                }
            }} color="primary" autoFocus>
                OK
            </Button>
            </DialogActions>
        </Dialog>
    </Grid>
  );
};

export default LoggingWidget;
