// ProjectDetail.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PersonIcon from '@mui/icons-material/Person';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { apiCall } from '../api'; // <-- Import the apiCall function

const ProjectDetail = () => {
    const { projectId } = useParams();
    const [projectData, setProjectData] = useState(null);
    const [timeLoggings, setTimeLoggings] = useState([]);
    const totalLoggedHours = timeLoggings.reduce((acc, log) => {
        if (log.attributes.service.data.attributes.Typ === 'Leistung') {
            return acc + log.attributes.LogDuration;
        }
        return acc;
    }, 0);
    
    const totalLoggedEuros = timeLoggings.reduce((acc, log) => {
        if (log.attributes.service.data.attributes.Typ !== 'Leistung') {
            return acc + log.attributes.LogDuration;
        }
        return acc;
    }, 0);    

    const generatePDF = () => {
        const element = document.querySelector('.project-detail-container'); // This selects the container you want to turn into a PDF
        const currentDate = new Date().toISOString().slice(0,10); // YYYY-MM-DD format
        const filename = `${currentDate}_${projectData.ProjectName}.pdf`;
    
        const opt = {
            margin:       10,
            filename:     filename,
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 2 },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
    
        html2pdf().from(element).set(opt).save();
    } 

    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const projectResponse = await apiCall({ endpoint: `projects/${projectId}?populate=*` });
                const projectAttributes = projectResponse.data.attributes;
                setProjectData(projectAttributes);
                
                // Fetch service details for each time logging entry
                const fetchServices = projectAttributes.time_loggings.data.map(log => {
                    return apiCall({ endpoint: `time-loggings/${log.id}?populate=*` });
                });
    
                const serviceResponses = await Promise.all(fetchServices);
                const logsWithServices = serviceResponses.map(res => res.data);
                setTimeLoggings(logsWithServices);
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        fetchProjectData();
    }, [projectId]);    

    if (!projectData) {
        return <Box>Loading...</Box>;
    }
   
    return (
        <Box>
            <Grid container spacing={3} p={3}>
                <Grid item xs={12}>
                    <h1>{projectData.ProjectName}</h1>
                </Grid>
                <Grid item xs={12}>
                    <Box className='ProjectFields'>
                    <TextField fullWidth id="project-number" label="Project Number" variant="standard" value={projectData.projectNumber || ""} readOnly />
                        <TextField fullWidth id="project-name" label="Project Name" variant="standard" value={projectData.ProjectName || ""} readOnly />
                        <TextField fullWidth id="project-description" label="Description" variant="standard" value={projectData.ProjectDescription || ""} readOnly />
                        {/* <TextField fullWidth id="project-active" label="Active" variant="standard" value={projectData.ProjectActive ? 'Yes' : 'No'} readOnly />
                        <TextField fullWidth id="created-at" label="Created At" variant="standard" value={new Date(projectData.createdAt).toLocaleString()} readOnly />
                        <TextField fullWidth id="updated-at" label="Updated At" variant="standard" value={new Date(projectData.updatedAt).toLocaleString()} readOnly />
                        <TextField fullWidth id="published-at" label="Published At" variant="standard" value={new Date(projectData.publishedAt).toLocaleString()} readOnly /> */}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {projectData.client && projectData.client.data ? (
                        <Link to={`/clients/${projectData.client.data.id}`} style={{ textDecoration: 'none' }}>
                            <Button startIcon={<PersonIcon />} fullWidth variant="contained" color="primary">
                                Kunde: {projectData.client.data.attributes.CompanyName}
                            </Button>
                        </Link>
                    ) : (
                        <Button fullWidth variant="contained" color="primary" disabled>
                            No Associated Client
                        </Button>
                    )}
                    <Box mt={2}>
                        <Button startIcon={<PictureAsPdfIcon />}  fullWidth variant="contained" color="primary" onClick={generatePDF}>Create PDF</Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Box} mt={4}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Beschreibung</TableCell>
                                    <TableCell align="right">Stunden / €</TableCell>
                                    <TableCell align="right">Leistung</TableCell>
                                    <TableCell align="right">Nutzer</TableCell>
                                    <TableCell align="right">Datum</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {timeLoggings.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            Es ist ruhig... zu ruhig. Keine Stunden geloggt.
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    timeLoggings.map((log) => (
                                        <TableRow key={log.id}>
                                            <TableCell component="th" scope="row">
                                                {log.attributes.LogDescription}
                                            </TableCell>
                                            <TableCell align="right">{log.attributes.LogDuration}{log.attributes.service.data.attributes.Typ === 'Leistung' ? <span>h</span> : <span>€</span> }</TableCell>
                                            <TableCell align="right">
                                                {log.attributes.service && log.attributes.service.data ? log.attributes.service.data.attributes.ServiceName : 'N/A'}<br />
                                                {log.attributes.service && log.attributes.service.data ? log.attributes.service.data.attributes.Typ : 'N/A'}
                                            </TableCell>
                                            <TableCell align="right">
                                                {log.attributes.user && log.attributes.user.data && log.attributes.user.data.attributes ? log.attributes.user.data.attributes.username : 'Unknown'}
                                            </TableCell>
                                            <TableCell align="right">{new Date(log.attributes.createdAt).toLocaleDateString()}</TableCell>
                                        </TableRow>
                                    ))
                                )}
                                <TableRow>
                                    <TableCell colSpan={3} align="right">Stunden:</TableCell>
                                    <TableCell align="right">{totalLoggedHours}h</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={3} align="right">Einkäufe:</TableCell>
                                    <TableCell align="right">{totalLoggedEuros}€</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );    
};

export default ProjectDetail;