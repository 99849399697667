// RouteChangeAlert.js

import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { apiCall } from '../api';

const RouteChangeAlert = ({ onCloseDrawer }) => {
  const location = useLocation();
  const [show, setShow] = useState(true);
  const previousLocation = useRef(location.pathname); // Store the initial location

  useEffect(() => {
    if (location.pathname !== previousLocation.current) {
      console.log('Actual route change detected');
      setShow(true);
      if (onCloseDrawer) {
        onCloseDrawer(); // Close the drawer when the route changes
      }
      const checkTokenValidity = async () => {
        try {
          // Perform a simple API call to check token validity
          await apiCall({ endpoint: 'users/me', method: 'GET' });
        } catch (error) {
          console.log('ERROR')
        }
      };

      checkTokenValidity();

      previousLocation.current = location.pathname; // Update the previous location
    }
    const timer = setTimeout(() => setShow(false), 5000);
    return () => clearTimeout(timer);
  }, [location, onCloseDrawer]);

  if (!show) {
    return null;
  }

  return (
    <Alert severity="info">Page has changed to {location.pathname}</Alert>
  );
};

export default RouteChangeAlert;
