// CreateProject.js

import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { apiCall } from '../api'; // Import the apiCall function
import { Grid, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from 'react-router-dom';

const CreateProject = () => {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [projectName, setProjectName] = useState('');
    const [quotations, setQuotations] = useState([]);
    const [selectedQuotation, setSelectedQuotation] = useState(null);
    const [dialogMessage, setDialogMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();
    const [newProjectId, setNewProjectId] = useState(null);
    const [clientSearchTerm, setClientSearchTerm] = useState('');
    const [isQuotationDisabled, setIsQuotationDisabled] = useState(true);

    useEffect(() => {
        // If a client is selected, enable the Quotation field, otherwise disable it
        setIsQuotationDisabled(selectedClient ? false : true);

        // If a client is selected, fetch the quotations for that client
        if (selectedClient) {
            fetchQuotationsForClient(selectedClient.id);
        }
    }, [selectedClient]);

    const fetchQuotationsForClient = async (clientId) => {
        try {
            const endpoint = `quotations?filters[client][id][$eq]=${clientId}`;
            console.log("Quotations API Endpoint:", endpoint); // Log the API endpoint
            const response = await apiCall({ endpoint, method: 'GET' });
            console.log("Quotations API Response:", response); // Log the API response
            setQuotations(response.data);
        } catch (error) {
            console.error('Error fetching quotations for client:', error);
        }
    };    

    useEffect(() => {
        // Fetch clients
        const fetchClients = async () => {
            try {
                const endpoint = !clientSearchTerm || clientSearchTerm.length < 2 ? 
                    'clients' : `clients?_q=${clientSearchTerm}`;
                const response = await apiCall({ endpoint, method: 'GET' });
                setClients(response.data); // Ensure you're setting the state to the array within `data`.
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };        
    
        fetchClients();
    }, [clientSearchTerm]);    

    const handleCreateProject = async () => {
        if (!selectedClient || !projectName) {
            setDialogMessage('Bitte einen Kunden und ein Projekt auswählen.');
            setOpenDialog(true);
            return;
        }
    
        const payload = {
            data: {
                client: selectedClient.id,
                ProjectName: projectName,
                quotations: selectedQuotation ? selectedQuotation.id : undefined
            }
        };
    
        try {
            const response = await apiCall({ endpoint: 'projects', method: 'POST', data: payload });
            setSelectedClient(null);
            setProjectName('');
            setSelectedQuotation(null);
            setDialogMessage('Project Created Successfully');
            setOpenDialog(true);
            setNewProjectId(response.data.id); // Adjust this line based on the structure of your API response
        } catch (error) {
            console.error('Error creating project:', error);
        }
    };       

    console.log("Quotations State: ", quotations);

    return (
        <Box>
            <Grid className='createProjectWidget' container direction="column" spacing={2}>
                <Grid item>
                    <TextField
                        fullWidth
                        label="Projekt"
                        variant="outlined"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        options={clients}
                        getOptionLabel={(option) => option.attributes.CompanyName}
                        isOptionEqualToValue={(option, value) => option.id === value.id} // Ensure equality check is correct
                        onInputChange={(event, newInputValue) => {
                            setClientSearchTerm(newInputValue);
                        }}
                        onChange={(event, newValue) => {
                            console.log("New client value: ", newValue);
                            setSelectedClient(newValue);
                        }}                        
                        value={selectedClient} // Ensure the value prop is provided
                        renderInput={(params) => <TextField {...params} label="Kunde" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        options={quotations}
                        getOptionLabel={(option) => option.attributes.QuotationName} // Adjust if necessary
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => setSelectedQuotation(newValue)}
                        disabled={isQuotationDisabled} // Disable or enable based on the state
                        renderInput={(params) => <TextField {...params} label="Angebot" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleCreateProject}>
                        Anlegen
                    </Button>
                </Grid>
            </Grid>
            <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
                >
            <DialogTitle id="alert-dialog-title">Benachrichtigung</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => {
                setOpenDialog(false);
                if (newProjectId) { 
                    navigate(`/projects/${newProjectId}`);
                }
            }} color="primary" autoFocus>
                OK
            </Button>
            </DialogActions>
            </Dialog>
        </Box>
    
    );
};

export default CreateProject;
