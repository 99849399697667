// CreateQuotation.js

import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { apiCall } from '../api'; // Import the apiCall function
import { Grid, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from 'react-router-dom';

const CreateQuotation = () => {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [quotationName, setQuotationName] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();
    const [newQuotationId, setNewQuotationId] = useState(null);
    const [clientSearchTerm, setClientSearchTerm] = useState('');
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [isProjectDisabled, setIsProjectDisabled] = useState(true);

    useEffect(() => {
        setIsProjectDisabled(selectedClient ? false : true);
    
        if (selectedClient) {
            fetchProjectsForClient(selectedClient.id);
        }
    }, [selectedClient]);    

    const fetchProjectsForClient = async (clientId) => {
        try {
            const endpoint = `projects?filters[client][id][$eq]=${clientId}`;
            const response = await apiCall({ endpoint, method: 'GET' });
            setProjects(response.data);
        } catch (error) {
            console.error('Error fetching projects for client:', error);
        }
    };    

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const endpoint = !clientSearchTerm || clientSearchTerm.length < 2 ? 
                    'clients' : `clients?_q=${clientSearchTerm}`;
                const response = await apiCall({ endpoint, method: 'GET' });
                setClients(response.data);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchClients();
    }, [clientSearchTerm]);

    const handleCreateQuotation = async () => {
        if (!quotationName || !selectedClient) {
            setDialogMessage('Bitte einen Kunden auswählen und das Angebot benennen.');
            setOpenDialog(true);
            return;
        }

        const payload = {
            data: {
                client: selectedClient.id, 
                QuotationName: quotationName,
                project: selectedProject ? selectedProject.id : undefined,
            }
        };

        try {
            const response = await apiCall({ endpoint: 'quotations', method: 'POST', data: payload });
            setQuotationName('');
            setSelectedClient(null);
            setDialogMessage('Quotation Created Successfully');
            setOpenDialog(true);
            setNewQuotationId(response.data.id);
        } catch (error) {
            console.error('Error creating quotation:', error);
        }
    };

    return (
        <Box>
            <Grid className='createQuotationWidget' container direction="column" spacing={2}>
                <Grid item>
                    <TextField
                        fullWidth
                        label="Angebot"
                        variant="outlined"
                        value={quotationName}
                        onChange={(e) => setQuotationName(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        options={clients}
                        getOptionLabel={(option) => `${option.attributes.CompanyName}`} // Assuming the client object has a client attribute with a CompanyName
                        isOptionEqualToValue={(option, value) => option.id === value.id} // Ensure equality check is correct
                        onInputChange={(event, newInputValue) => {
                            setClientSearchTerm(newInputValue);
                        }}
                        onChange={(event, newValue) => setSelectedClient(newValue)}
                        renderInput={(params) => <TextField {...params} label="Kunde" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        options={projects}
                        getOptionLabel={(option) => option.attributes.ProjectName}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => setSelectedProject(newValue)}
                        disabled={isProjectDisabled}
                        renderInput={(params) => <TextField {...params} label="Projekt" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleCreateQuotation}>
                        Anlegen
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Benachrichtigung</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenDialog(false);
                        if (newQuotationId) { 
                            navigate(`/quotations/${newQuotationId}`);
                        }
                    }} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CreateQuotation;
