// ProjectList.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { apiCall } from '../api';
import { API_URL } from '../config';

function ProjectList() {
    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get('clientId');


    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const start = page * rowsPerPage;
        let endpoint = `projects?sort=id:desc&populate=client&pagination[limit]=${rowsPerPage}&pagination[start]=${start}`;
        
        if (clientId) {
            endpoint += `&filters[client][id][$eq]=${clientId}`;
        }
    
        console.log("Fetching from URL:", `${API_URL}/${endpoint}`);
        
        apiCall({ endpoint })
        .then(data => {
            console.log("API Response:", data); // Add this line
            setProjects(data.data);
            if (data && data.meta && data.meta.pagination && data.meta.pagination.total) {
                setTotalCount(data.meta.pagination.total);
            }                
        })        
        .catch(error => {
            console.error("Error fetching quotations:", error);
        });
    }, [page, rowsPerPage, clientId]); // Added clientId to dependencies 

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function hexToRgb(hex) {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
    }    

    const rgb = theme.palette.mode === 'dark' 
    ? hexToRgb(theme.palette.primary.dark)
    : hexToRgb(theme.palette.primary.light);

    const hoverColor = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.1)`;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid>
                <h1>Projekte</h1>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Projekt</TableCell>
                                <TableCell>Kunde</TableCell>
                                <TableCell>Zuletzt bearbeitet</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projects.map(project => (
                                <TableRow 
                                    key={project.id}
                                    onClick={() => navigate(`/projects/${project.id}`)}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: hoverColor,
                                        },
                                    }}
                                >
                                    <TableCell>
                                        {project.attributes.projectNumber}
                                    </TableCell>
                                    <TableCell>
                                        {project.attributes.ProjectName}
                                    </TableCell>
                                    <TableCell>
                                        {project.attributes.client && project.attributes.client.data ? project.attributes.client.data.attributes.CompanyName : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                        {project.attributes.updatedAt}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={totalCount} // <-- This line is changed
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Grid>
        </Box>
    );
}

export default ProjectList;
