// NewInvoice.js

import React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

const NewInvoice = () => {
  return (
    <Box className="new-container" sx={{ flexGrow: 1 }}>
        <h1>Neue <strong>Rechnung</strong></h1>
        <Grid container justifyContent="center" p={3}>
            <Grid item xs={6}> {/* This will take up half the space of its container */}
               
            </Grid>
        </Grid>
    </Box>
  );
};


export default NewInvoice;