// InvoiceList.js

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, Grid } from '@mui/material';

function InvoiceList() {

    return (
      <Box sx={{ flexGrow: 1 }}>
          <Grid>
              <h1>Rechnungen</h1>
              <TableContainer component={Paper}>
                  <Table>
                      <TableHead>
                          <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell>Kunde</TableCell>
                              <TableCell>Datum</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>                           
                      </TableBody>
                  </Table>
                  <TablePagination />
              </TableContainer>
          </Grid>
      </Box>
  );
}

export default InvoiceList;