// ClientList.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { apiCall } from '../api';

function ClientList() {
    const [clients, setClients] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClients = async () => {
          try {
            const data = await apiCall({ endpoint: 'clients' });
            setClients(data.data);
          } catch (error) {
            console.error('Error fetching clients:', error);
            // Handle error (e.g., set an error state)
          }
        };
        fetchClients();
      }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function hexToRgb(hex) {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
    }    

    const rgb = theme.palette.mode === 'dark' 
    ? hexToRgb(theme.palette.primary.dark)
    : hexToRgb(theme.palette.primary.light);

    const hoverColor = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.1)`;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid>
                <h1>Kunden</h1>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Firmenname</TableCell>
                                <TableCell>Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(client => (
                                <TableRow 
                                    key={client.id}
                                    onClick={() => navigate(`/clients/${client.id}`)}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: hoverColor,
                                        },
                                    }}
                                >
                                <TableCell>
                                    {client.attributes.CompanyName}
                                </TableCell>
                                <TableCell>
                                    {client.attributes.CompanyEmail}
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={clients.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Grid>
        </Box>
    );
}

export default ClientList;
