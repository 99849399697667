// CreateClient.js

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { apiCall } from '../api'; // Import the apiCall function
import { Grid, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from 'react-router-dom';

const CreateClient = () => {
    const [clientData, setClientData] = useState({
        CompanyName: '',
        CompanyEmail: '',
        CompanyNameAdd: '',
        CompanyStreet: '',
        CompanyZIP: '',
        CompanyCity: '',
        CompanyCountry: '',
        CompanyPhone: '',
        CompanyURL: ''
    });
    const [dialogMessage, setDialogMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [newClientId, setNewClientId] = useState(null); // Store the new client's ID
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClientData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCreateClient = () => {
        if (!clientData.CompanyName || !clientData.CompanyEmail) {
            setDialogMessage('Bitte Kundennamen und E-Mail eingeben.');
            setOpenDialog(true);
            return;
        }

        // Use apiCall function instead of axios
        apiCall({ 
            endpoint: 'clients', 
            method: 'POST', 
            data: { data: clientData } 
        })
        .then(response => {
            setClientData({
                CompanyName: '',
                CompanyEmail: '',
                CompanyNameAdd: '',
                CompanyStreet: '',
                CompanyZIP: '',
                CompanyCity: '',
                CompanyCountry: '',
                CompanyPhone: '',
                CompanyURL: ''
            });
            setDialogMessage('Client Created Successfully');
            setOpenDialog(true);
            setNewClientId(response.data.id); // Store the new client's ID
        })
        .catch(error => {
            console.error('Error creating client:', error);
        });
    };

    return (
        <Box>
            <Grid className='createClientWidget' container direction="column" spacing={2}>
                <Grid item>
                    <TextField
                        fullWidth
                        label="Kundenname"
                        variant="outlined"
                        name="CompanyName"
                        value={clientData.CompanyName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        label="E-Mail"
                        variant="outlined"
                        name="CompanyEmail"
                        value={clientData.CompanyEmail}
                        onChange={handleInputChange}
                    />
                </Grid>
                {/* Add more input fields similarly for other client attributes */}
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleCreateClient}>
                        Kunde anlegen
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Benachrichtigung</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenDialog(false);
                        if (newClientId) { 
                            navigate(`/clients/${newClientId}`); // Redirect to the new client's detail page
                        }
                    }} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CreateClient;
