// QuotationList.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { apiCall } from '../api';

function QuotationList() {
    const [quotations, setQuotations] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get('clientId');
    const [totalCount, setTotalCount] = useState(0);
  
    useEffect(() => {
        const start = page * rowsPerPage; // Calculate the starting point
        let endpoint = `quotations?sort=id:desc&populate=*&pagination[limit]=${rowsPerPage}&pagination[start]=${start}`; // Add the start parameter
        
        if (clientId) {
            endpoint += `&filters[client][id][$eq]=${clientId}`;
        }
    
        console.log("Fetching from URL:", `${API_URL}/${endpoint}`);
    
        // Use apiCall instead of axios
        apiCall({ endpoint })
        .then(data => {
            console.log("API Response:", data); // Add this line
            setQuotations(data.data);
            if (data && data.meta && data.meta.pagination && data.meta.pagination.total) {
                setTotalCount(data.meta.pagination.total);
            }                
        })        
        .catch(error => {
            console.error("Error fetching quotations:", error);
        });
    }, [page, rowsPerPage, clientId]); // Added clientId to dependencies 

      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };

      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

      function hexToRgb(hex) {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
      }    

      const rgb = theme.palette.mode === 'dark' 
      ? hexToRgb(theme.palette.primary.dark)
      : hexToRgb(theme.palette.primary.light);

      const hoverColor = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.1)`;
    
    
    return (
      <Box sx={{ flexGrow: 1 }}>
          <Grid>
              <h1>Angebote</h1>
              <TableContainer component={Paper}>
                  <Table>
                      <TableHead>
                          <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell>Angebot</TableCell>
                              <TableCell>Kunde</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                            {quotations.map(quotation => (
                                <TableRow 
                                    key={quotation.id}
                                    onClick={() => navigate(`/quotations/${quotation.id}`)}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: hoverColor,
                                        },
                                    }}
                                >
                                    <TableCell>
                                        {quotation.attributes.quotationNumber}
                                    </TableCell>
                                    <TableCell>
                                        {quotation.attributes.QuotationName}
                                    </TableCell>
                                    <TableCell>
                                        {quotation.attributes.client && quotation.attributes.client.data ? quotation.attributes.client.data.attributes.CompanyName : 'N/A'}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                  </Table>
                  <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={totalCount} // <-- This line is changed
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                  />
              </TableContainer>
          </Grid>
      </Box>
  );
}

export default QuotationList;