// HomePage.js

import React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

const HomePage = () => {
  const username = localStorage.getItem('username');

  return (
    <Box className="home-container" sx={{ flexGrow: 1 }}>
      <Grid item>
        <h1>kiel<strong>team</strong></h1>
        <h2>Hallo <span className='capitalize'>{username}.</span><br />
        Schau dich um und teste alle Funktionen aus.</h2>
      </Grid>
    </Box>
  );
};

export default HomePage;